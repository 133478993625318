import React from 'react';

const NavFooter = () => {
  return (
    <footer className="nav-footer">
      <div className="footer-section">
        <h4 className="footer-heading">22BET</h4>
        <ul>
          <li>About us</li>
          <li>Contacts</li>
          <li>Terms and Conditions</li>
          <li>Cookie Policy</li>
          <li>Privacy Policy</li>
          <li>FAQ</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">BETTING</h4>
        <ul>
          <li>Sports</li>
          <li>Live</li>
          <li>Jackpot</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">GAMES</h4>
        <ul>
          <li>Slots</li>
          <li>Live Casino</li>
          <li>Games</li>
          <li>TV Games</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">STATISTICS</h4>
        <ul>
          <li>Statistics</li>
          <li>Results</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4 className="footer-heading">USEFUL LINKS</h4>
        <ul>
          <li>Affiliate Program</li>
          <li>Become an agent</li>
          <li>Bet slip check</li>
          <li>Mobile version</li>
          <li>22bet news</li>
        </ul>
      </div>
    </footer>
  );
};

export default NavFooter;
