import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faDice, faTowerBroadcast, faMeteor, faTrophy, faBaseball, faGamepad, faFish, faTv, faGaugeMed, faCashRegister, faXmark } from '@fortawesome/free-solid-svg-icons';


function MobileMenu(){

    const closeMobileNav = () => {
        const chatElement = document.querySelector('.mobile-nav-contaniner');
          chatElement.classList.remove('open');
        
      };
    return(
    
            <div className="mobile-nav-contaniner">
                <div className="mobile-nav">
                <div className="nav-header">
                <a href="/"><img src="assets/logo.png" alt=""/></a>  

                <a href="/" className="nav-home-icon"><FontAwesomeIcon icon={faHouse} /></a>
                </div>
               <div>
                    <a href="/login" className="btn-ui login">Log In</a>
                    <a href="/account" className="btn-ui reg">Registration</a>
               </div>
                    <ul style={{padding: '0px', margin: '0px'}}>
                    
                        <li className="nav-item"><a className="nav-link" href="/"><FontAwesomeIcon className="nav-icon" icon={faHouse} /> Home</a></li>
                        <li className="nav-item"><a className="nav-link" href="/sports"> <FontAwesomeIcon className="nav-icon" icon={faBaseball} /> Sports</a></li>
                        <li className="nav-item"><a className="nav-link" href="/live"> <FontAwesomeIcon className="nav-icon" icon={faTowerBroadcast} /> Live</a></li>
                        <li className="nav-item"><a className="nav-link" href="/casino"> <FontAwesomeIcon className="nav-icon" icon={faDice} /> casino</a></li>
                        <li className="nav-item"><a className="nav-link" href="/poker"><FontAwesomeIcon className="nav-icon" icon={faTrophy} /> Poker</a></li>
                        <li className="nav-item"><a href="/video-slots" className="nav-link"><FontAwesomeIcon className="nav-icon" icon={faGaugeMed} /> Slot</a></li>
                        <li className="nav-item"><a href="/video-poker" className="nav-link"><FontAwesomeIcon className="nav-icon" icon={faTv} /> Video Poker</a></li>

                    </ul>
                </div>
                <div className="mobile-nav-close">
                <FontAwesomeIcon className="nav-icon" onClick={closeMobileNav} icon={faXmark} />
                </div>
            </div>

        
    );
}

export default MobileMenu;