import React from "react";
import PromoList from "../component/Grid/Bonus/PromoList";
import BonusNavBar from "../component/Grid/Bonus/BonusNav";

function Bonus (){
    return(
        <>
        <BonusNavBar/>
        <PromoList/>
        </>
    );
}

export default Bonus;