import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

function Poker() {
    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isMobile, setIsMobile] = useState(false); // State to track if device is mobile
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch games data from the API
        fetch("https://apis.143bet.live/api/games?type=poker")
            .then(response => response.json())
            .then(data => {
                setGames(data.games);
                setFilteredGames(data.games); // Initially set filtered games to all games
            })
            .catch(error => {
                console.error('Error fetching games:', error);
            });

        // Check if the device is mobile
        const isMobileDevice = window.innerWidth <= 768; // Adjust the width threshold as needed
        setIsMobile(isMobileDevice);
        console.log('Is Mobile:', isMobileDevice); // Add this line for debugging
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter games based on the search query
        const filtered = games.filter(game => game.name.toLowerCase().includes(query));
        setFilteredGames(filtered);
    };

    const handleGameClick = async (gameId) => {
        try {
          // Fetch the user's authentication token from localStorage
          const token = localStorage.getItem('token');
          
          // Ensure that the user is logged in before making the request
          if (!token) {
            setError('User not logged in');
            return;
          }
      
          // Fetch the game details from the Laravel backend
          const response = await fetch('https://apis.143bet.live/api/get-game-direct', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Include the authentication token
            },
            body: JSON.stringify({
              game_id: gameId,
              lang: 'en', // Replace with the desired language
              play_for_fun: false, // Replace with the desired play_for_fun value
              home_url: 'https://www.143bet.live', // Replace with your actual home URL
            }),
          });
      
          const data = await response.json();
      
          if (response.ok) {
            navigate('/game', { state: { iframeUrl: data.response.response.url } }); // Use navigate function to navigate to the '/game' route
            console.log(data.response);
          } else {
            setError(data.message || 'An unexpected error occurred');
          }
        } catch (error) {
          console.error('Error fetching game details:', error);
          setError('An unexpected error occurred');
        }
      };

    return (
        <div className="casino">
            <div className="search-bar">
                <input
                    placeholder="Search"
                    type="search"
                    name="search"
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>
            <div className="row m-2">
                {filteredGames.map(game => (
                    // Render the game only if it matches the mobile condition
                    (!isMobile && !game.mobile) || (isMobile && game.mobile) ? (
                        <div key={game.id} className="col-lg-3 col-6 my-2 casino-list">
                            <img src={game.image_preview} alt={game.name} />
                            <div className="casino-list-hover">
                                <button onClick={() => handleGameClick(game.id)}>Play Now</button>
                                
                                <p>{game.name}</p>
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
        </div>
    );
}

export default Poker;
