import React from "react";

function About(){
    return(
        <div className="about-us text-center">
            <h1>ABOUT US</h1>
        <div className="about-content m-5">
            <img src="https://22bet.ng/genfiles/cms/151-322/mobile/images/22bet/about-us-bg.jpg" alt="About"/>
            <h3>22BET BETTING COMPANY</h3>
            <p>22bet is a young and quickly growing company. Over 400,000 online users bet with .</p>
            <h3>SPORTS</h3>
            <p>22bet Sportsbook includes over 1,000 events daily. You can bet on a variety of popular sports: football, tennis, basketball, volleyball, ice hockey, golf, boxing, handball, American football, hockey, baseball, table tennis, biathlon, Aussie rules and bandy. We also offer bets on cricket, snooker, Formula 1, cycling, ski jumping, curling, floorball, inline hockey and water polo. With 22bet, you can bet on more than 1,000 sporting events every day.</p>
            <h3>TYPES OF BETS</h3>
            <p>22bet offers many additional markets on specific events: European handicap, correct score, run of play, over/under, team to score first etc. We also have a great selection of markets on international games on a club and country level.

In individual sports such as cycling, golf, athletics, skiing etc, apart from outright betting, we also offer head-to-heads on two selected athletes at all times.

22bet offers types of bets such as singles, accumulators, and system bets.

Every day 22bet presents "The Matches of the Day", which comprise the best daily offers. We offer you enhanced odds and increased stake amounts on all those matches!</p>
        <h3>CUSTOMER SUPPORT</h3>
        <p>The 22bet Customer Support Team is here for you 24 hours a day, 7 days a week. You can use our online chat on the website or contact us by e-mail. All 22bet contact details are listed on the CONTACTS page. We are happy to help any time.</p>
        <h3>DEPOSIT/WITHDRAWAL</h3>
        <p>You can top up your account at 22bet betting shops or via various e-payment systems.

Winnings are paid out through the same method that was used to make a deposit.

</p>
<h3>LIVE BETS</h3>
<p>Live bets are available 24 hours a day. For most sports, more than 30 markets are offered for each event, including bets on corners, yellow cards, free kicks etc.</p>
        </div>
        </div>
    );
}
export default About;