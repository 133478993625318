import React from 'react';


const BonusNavBar = () => {
  return (
    <>
        <h2 className="navbar-title">BONUSES AND PRIZES FROM 22BET</h2>
        <div className="navbar-container">
      
      <ul className="navbar-menu">
        <li className="navbar-item">TOURNAMENTS</li>
        <li className="navbar-item">SHOP</li>
        <li className="navbar-item">CASINO</li>
        <li className="navbar-item">SPORTS</li>
        <li className="navbar-item">ALL</li>
      </ul>
     
     
    </div>
    </>
    
  );
};

export default BonusNavBar;
