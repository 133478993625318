import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDownload, faUpload, faClock,  faRightLeft,  faRightFromBracket,} from '@fortawesome/free-solid-svg-icons';
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

function Account(){
  const [userData, setUserData] = useState(null);
  const [agentData, setAgentData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);


    const [activeKey, setActiveKey] = useState('profile');

    const [query, setQuery] = useState('');


 

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('sessionid');
    localStorage.removeItem('remote_id');
    localStorage.removeItem('username');
    
    // Redirect to the login page
    window.location.href = '/login';
  };
    
   
   
   useEffect(() => {
    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        
        if (token && remoteId) {
            try {
                const response = await fetch(`https://apis.143bet.live/api/user/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    
                    setUserData(data);
                    
                } else {
                    console.error('Failed to fetch user data with status:', response.status);
                    // Optionally, read and log the response text to see the error page content
                    const text = await response.text();
                    console.error('Error page content:', text);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };

    fetchUserData();
}, []);
useEffect(() => {
  const fetchAgentData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');
      
      if (token && remoteId) {
          try {
              const response = await fetch(`https://apis.143bet.live/api/agent/show?remote_id=${remoteId}`, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  },
              });

              if (response.ok) {
                  const data = await response.json();
                  console.log(data);
                  setAgentData(data);
              } else {
                  console.error('Failed to fetch user data with status:', response.status);
                  // Optionally, read and log the response text to see the error page content
                  const text = await response.text();
                  console.error('Error page content:', text);
              }
          } catch (error) {
              console.error('Error fetching user data:', error);
          }
      }
  };

  fetchAgentData();
}, []);

useEffect(() => {
  const fetchTransData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');
      
      if (token && remoteId) {
          try {
              const response = await fetch(`https://apis.143bet.live/api/transaction/show?remote_id=${remoteId}`, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                  },
              });

              if (response.ok) {
                  const data = await response.json();
                  console.log(data);
                  setTransactionData(data);
              } else {
                  console.error('Failed to fetch user data with status:', response.status);
                  // Optionally, read and log the response text to see the error page content
                  const text = await response.text();
                  console.error('Error page content:', text);
              }
          } catch (error) {
              console.error('Error fetching user data:', error);
          }
      }
  };

  fetchTransData();
}, []);
   
  



    return(
        <div className="account">
                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">

                    <div className='row'>
                        <div className='col-lg-2 account-nav'>
                        <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                        <Nav.Link eventKey="profile" onClick={() => setActiveKey('profile')}> <FontAwesomeIcon icon={faUser} /> <span className='account-nav-link'>Personal Profile</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="deposit" onClick={() => setActiveKey('deposit')}><FontAwesomeIcon icon={faDownload} /><span className='account-nav-link'>Deposit</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="withdraw" onClick={() => setActiveKey('withdraw')}><FontAwesomeIcon icon={faUpload} /> <span className='account-nav-link'>Withdraw Funds</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="bet" onClick={() => setActiveKey('bet')}><FontAwesomeIcon icon={faClock} /><span className='account-nav-link'>Bet History</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="transaction" onClick={() => setActiveKey('transaction')}><FontAwesomeIcon icon={faRightLeft} /><span className='account-nav-link'>transaction History</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link onClick={logout} ><FontAwesomeIcon icon={faRightFromBracket} /><span className='account-nav-link'>log out</span></Nav.Link>
                        </Nav.Item>
                    </Nav>
                        </div>
            <div className='col-lg-10 account-content'>

                        <Tab.Content>
                        <Tab.Pane eventKey="profile" active={activeKey === 'profile' } className='p-3'>
                        <div className='row'>
                            <h3>PERSONAL PROFILE</h3>
                            <p>Fill in the empty fields to take advantage of the enhanced features of the website!</p>
                        </div>
                        
                        <div className='row my-3'>
                        {userData ? (
                            <div className='col-lg-12'>
                                <div className='account-title p-3 rounded'>ACCOUNT INFO</div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Remote ID</div>
                                    <div> {userData.remote_id}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Full Name</div>
                                    <div> {userData.name}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Username</div>
                                    <div> {userData.username}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Phone</div>
                                    <div> {userData.phone}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Address</div>
                                    <div> {userData.address}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>country</div>
                                    <div> {userData.country}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>REGISTRATION DATE</div>
                                    <div> {formatDate(userData.created_at)}</div>
                                    
                                </div>
                             
                            </div>
                             ) : (
                              <p>Loading user data...</p>
                          )}
                            
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="deposit" active={activeKey === 'deposit'}>
                         
                        <div className='row my-3'>
                        {agentData ? (
                            <div className='col-lg-12'>
                                <div className='account-title p-3 rounded'>Agent INFO</div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Agent ID</div>
                                    <div> {agentData.id}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Full Name</div>
                                    <div> {agentData.name}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Username</div>
                                    <div> {agentData.username}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Phone</div>
                                    <div> {agentData.phone}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Whats App</div>
                                    <div> {agentData.whatsapp}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Telegram</div>
                                    <div> {agentData.telegram}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>country</div>
                                    <div> {agentData.country}</div>
                                </div>
                               
                             
                            </div>
                             ) : (
                              <p>Loading Agent data...</p>
                          )}
                            
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="withdraw" active={activeKey === 'withdraw'}>
                        
                        <div className='row my-3'>
                        {agentData ? (
                            <div className='col-lg-12'>
                                <div className='account-title p-3 rounded'>Agent INFO</div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Agent ID</div>
                                    <div> {agentData.id}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Full Name</div>
                                    <div> {agentData.name}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Username</div>
                                    <div> {agentData.username}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Phone</div>
                                    <div> {agentData.phone}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Whats App</div>
                                    <div> {agentData.whatsapp}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>Telegram</div>
                                    <div> {agentData.telegram}</div>
                                </div>
                                <div className='d-flex justify-content-between m-3'>
                                    <div>country</div>
                                    <div> {agentData.country}</div>
                                </div>
                               
                             
                            </div>
                             ) : (
                              <p>Loading Agent data...</p>
                          )}
                            
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="bet" active={activeKey === 'bet'}>
                          <div className='p-3'>
                    <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Game ID</th>
                            <th scope="col">D/C</th>
                            <th scope="col">TRX ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                        {transactionData.map((transaction, index) => {
                                if (transaction.action === 'debit' || transaction.action === 'credit') {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{transaction.game_id}</td>
                                      <td>{transaction.action}</td>
                                      <td>{transaction.transaction_id}</td>
                                      <td>{transaction.amount}</td>
                                      <td>{formatDate(transaction.created_at)}</td>
                                    </tr>
                                  );
                                } else {
                                  return null; // Render nothing for other action types
                                }
                              })}

                                                
                        
                        </tbody>
                      </table>


                          </div>
   
                        </Tab.Pane>
                       
                       
                       
                        <Tab.Pane eventKey="transaction" active={activeKey === 'transaction'}>
                        <div className='p-3'>
                    <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Account</th>
                            <th scope="col">Amount</th>
                            <th scope="col">W/D</th>
                            <th scope="col">TRX ID</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                        {transactionData.map((transaction, index) => {
                                if (transaction.action === 'deposit' || transaction.action === 'withdraw') {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{transaction.provider}</td>
                                      <td>{transaction.amount}</td>
                                      <td>{transaction.action}</td>
                                      <td>{transaction.transaction_id}</td>
                                      <td>{formatDate(transaction.created_at)}</td>
                                    </tr>
                                  );
                                } else {
                                  return null; // Render nothing for other action types
                                }
                              })}

                                                
                        
                        </tbody>
                      </table>


                          </div>
                        </Tab.Pane>
                       
                       
                    </Tab.Content>
                        </div>
                    </div>

     
    
    </Tab.Container>
        </div>
    )
}
export default Account;