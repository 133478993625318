import React, { useState } from 'react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    try {
      const response = await fetch('https://apis.143bet.live/api/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        console.log(data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('sessionid', data.response.response.sessionid);
        localStorage.setItem('remote_id', data.response.response.id);
        localStorage.setItem('username', data.response.response.username);
        
        // Redirect to another page or update the state to indicate successful login
        // For example:
        window.location.href = '/'; // Redirect to a dashboard page
      } else {
        console.log(data.message || 'An unexpected error occurred');  // Assuming the server sends an error message in the response
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>LOG IN</h2>
        <div className="input-group">
          <input
            type="text"
            placeholder="Username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="options">
          <label className="remember-me">
            <input type="checkbox" />
            Remember
          </label>
          <a href="/">Forgot your password?</a>
        </div>
        <button type="submit" className="login-btn">LOG IN</button>
        <div className="register-link">
          Not registered? <a href="/">REGISTER</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
