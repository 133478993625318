import React from 'react';
import Slider from 'react-slick';
import { Tab, Row, Col, Nav } from 'react-bootstrap';

const Registration = () => {
  // Settings for the Slick Slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="registration-container">
      <Slider {...sliderSettings}>
        {/* Add your sliding elements here */}
        <div>Slide Content 1</div>
        <div>Slide Content 2</div>
      </Slider>

      <Tab.Container id="registration-tabs" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">By Phone</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Full Registration</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Social Networks and Messengers</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* Content for phone registration */}
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {/* Content for full registration */}
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                {/* Content for social networks and messengers registration */}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default Registration;
