import React from "react";
function Rocket (){
    return(
        <div className="sports">
          <iframe
        src="https://eu-staging.ssgportal.com/GameLauncher/Loader.aspx?GameCategory=JetX&GameName=JetX&Token=DEMO&PortalName=demo&ReturnUrl=&Lang=en"
        title="Example"
        width="100%"
        height="1000vh"
        frameBorder="0"
        allowFullScreen
        style={{ backgroundColor: 'white', scrollbarWidth: 'none' }} // Set background color to white
      ></iframe>
        </div>
    );
}

export default Rocket;