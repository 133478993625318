import React from 'react';


const PromoCard = ({ backgroundImage, title, description, buttonText }) => {
  return (
        <div className='col-lg-3 my-2'>
      <div className="promo-card">
        <div className="promo-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="header-content">
            {/* Content that might be dynamic can also go here */}
          </div>
        </div>
        <div className="promo-body">
          <h1 className="promo-title">{title}</h1>
          <p className="promo-description">{description}</p>
          <button className="promo-button">{buttonText}</button>
        </div>
      </div>
    </div>
       

  );
};

export default PromoCard;
