import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/topmenu.css';
import './assets/css/PromoCard.css';
import './assets/css/main.css';
import './assets/css/account.css';
import './assets/css/Poker.css';
import './assets/css/footer.css';
import './assets/css/reponsive.css';
import './assets/css/mobileMenu.css';
import './assets/css/login.css';
import TopMenu from './component/nav/TopMenu';
import Sports from './component/games/Sports';
import NavFooter from './component/nav/NavFooter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Bonus from './pages/Bonus';
import Poker from './pages/Poker';
import Footer from './component/layout/Footer';
import Account from './component/account/Account';
import Rocket from './component/games/Rocket';
import About from './pages/About';
import Login from './pages/Login';
import Registration from './pages/Registration';
import MobileMenu from './component/nav/MobileMenu';
import Casino from './pages/Casino';
import GameLunch from './component/games/GameLunch';
import LiveCasino from './pages/LiveCasino';
import VideoPoker from './pages/VideoPoker';
import VideoSlot from './pages/VideoSlots';
import Signup from './pages/Signup';


function App() {
  return (
    <div className="App">
     <Router>
     <MobileMenu/>
     <TopMenu/>
     <Routes>
     <Route path='/' element={<Sports />} />
     <Route path='/sports' element={<Sports />} />
     <Route path='/rocket' element={<Rocket />} />
     <Route path='/bounus' element ={<Bonus/>}/>
     <Route path='/poker' element ={<Poker/>}/>
     <Route path='/video-poker' element ={<VideoPoker/>}/>
     <Route path='/video-slots' element ={<VideoSlot/>}/>
     <Route path='/casino' element ={<Casino/>}/>
     <Route path='/signup' element ={<Signup/>}/>
     <Route path='/live' element ={<LiveCasino/>}/>
     <Route path='/account' element ={<Account/>}/>
     <Route path='/login' element ={<Login/>}/>
     <Route path='/about' element ={<About/>}/>
     <Route path='/game' element ={<GameLunch/>}/>
     <Route path='/registration' element ={<Registration/>}/>
     </Routes>
      <NavFooter/>
      <Footer/>
     </Router>
    </div>
  );
}

export default App;
