import React from 'react';
import PromoCard from './PromoCard'; // Make sure this is the correct path to your PromoCard component

// Promo data array
const promoDataArray = [
  {
    id: 1,
    backgroundImage: 'https://22bet.ng/genfiles/cms/151-151/desktop/bonus/rules/1st-sport.png',
    title: 'WELCOME BONUS UP TO 207500 NGN FOR SPORTS BETTING',
    description: 'Make your first deposit and get a 100% bonus up to 207500 NGN',
    buttonText: 'FIND OUT MORE'
  },
  {
    id: 2,
    backgroundImage: 'https://22bet.ng/genfiles/cms/151-151/desktop/bonus/rules/reload-sport.png',
    title: 'FRIDAY RELOAD SPORTSBOOK BONUS',
    description: 'Boost your sports betting balance with a Bonus up to 170 000 NGN! Make a deposit on Friday to get a 100% bonus up to 170 000 NGN and participate in our Friday RELOAD promotion',
    buttonText: 'FIND OUT MORE'
  },
  {
    id: 3,
    backgroundImage: 'https://22bet.ng/genfiles/cms/151-322/desktop/bonuses/casino.png',
    title: 'FIRST DEPOSIT BONUS',
    description: 'Make your first deposit and get a 100% bonus up to 255000 NGN',
    buttonText: 'FIND OUT MORE'
  },
  {
    id: 4,
    backgroundImage: 'https://22bet.ng/genfiles/cms/151-337/desktop/bonus/rules/wednesday-cash-splash.png',
    title: 'WEDNESDAY CASH SPLASH!',
    description: 'Pick up an amazing 50% deposit bonus of up to 26400 BDT for your favorite casino slots',
    buttonText: 'FIND OUT MORE'
  },
  // ... Add more promo items as needed ...
];

// PromoList component
const PromoList = () => {
  return (
    <div className="row m-3">
      {promoDataArray.map((promo) => (
        <PromoCard
          key={promo.id}
          backgroundImage={promo.backgroundImage}
          title={promo.title}
          description={promo.description}
          buttonText={promo.buttonText}
        />
      ))}
    </div>
  );
};

export default PromoList;
