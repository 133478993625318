import React from 'react';


const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-top">
      <div className="payment-options">
            <img src='assets/payment.png' alt='Payment'/>
         </div>
       
       
       
        <div className="age-notice">
          <span>18+</span>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Arcadia Hospitality LTD, the provider of this website, is licensed by the National Lottery Regulatory Commission...</p>
      </div>
    </div>
  );
};

export default Footer;
