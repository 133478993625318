import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const openMobileNav = () => {
  const chatElement = document.querySelector('.mobile-nav-contaniner');
  if (chatElement) {
    chatElement.classList.add('open');
  } else {
    console.error('Element .mobile-nav-container not found');
  }
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('sessionid');
  localStorage.removeItem('remote_id');
  localStorage.removeItem('username');
  
  // Redirect to the login page
  window.location.href = '/login';
};

function TopMenu() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [balance, setBalance] = useState(localStorage.getItem('balance') || 0);

  const fetchBalance = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch('https://apis.143bet.live/api/balance', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBalance(data.balance);
          localStorage.setItem('balance', data.balance);
        } else {
          console.error('Failed to fetch balance with status:', response.status);
          const text = await response.text();
          console.error('Error page content:', text);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
      const interval = setInterval(fetchBalance, 30000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  return (
    <div className="topmenu">
      <div className="nav-logo">
        <a href="/"><img src="assets/logo.png" alt="Logo" /></a>
      </div>
      <div className="nav-menu">
        <ul style={{ borderRadius: '38px', background: 'rgb(255, 255, 255, 0.1)', margin: '0', height: '38px', padding: '0' }}>
          <li className="nav-item"><a href="/sports" className="nav-link">Sports</a></li>
          <li className="nav-item"><a href="/live" className="nav-link">Live</a></li>
          <li className="nav-item"><a href="/video-slots" className="nav-link">Slot</a></li>
          <li className="nav-item"><a href="/casino" className="nav-link">Casino</a></li>
          <li className="nav-item"><a href="/video-poker" className="nav-link">Video Poker</a></li>
          <li className="nav-item"><a href="/poker" className="nav-link">Poker</a></li>
          {isLoggedIn && (
  <li className="nav-item">
    <a href="/Account" className="nav-link">Account</a>
  </li>
)}

         
        </ul>
      </div>
      <div className="mobile-menu">
        <div className="mobile-icon">
          <FontAwesomeIcon onClick={openMobileNav} icon={faBars} />
        </div>
      </div>
      <div className="nav-action">
        {isLoggedIn ? (
          <>
            <a href="/" onClick={logout} className="btn-ui login media-1024">Log Out</a>
            <a href="/" className="btn-ui login media-1024">${balance}</a>
          </>
        ) : (
          <>
            <a href="/signup" className="btn-ui reg media-1024">Registration</a>
            <a href="/login" className="btn-ui login media-1024">Log In</a>
          </>
        )}
      </div>
    </div>
  );
}

export default TopMenu;
