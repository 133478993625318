import React, { useEffect, useRef, useState } from 'react';

// Function to load an external script and return a Promise that resolves when the script is loaded
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = (error) => reject(new Error(`Script load error for ${src}: ${error.message}`));
    document.body.appendChild(script);
  });
};

const Sports = () => {
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const fetchContentFromAPI = async () => {
    // Fetch the user's authentication token from localStorage
    const token = localStorage.getItem('token');

    // Determine play_for_fun value based on the presence of the token
    const playForFun = !token;
    console.log('play for fun:', playForFun);

    try {
      // Fetch the game details from the Laravel backend
      const response = await fetch('https://apis.143bet.live/api/get-game-direct', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '', // Include the authentication token if it exists
        },
        body: JSON.stringify({
          game_id: '35615',
          lang: 'en', // Replace with the desired language
          play_for_fun: playForFun, // Set play_for_fun based on token presence
          home_url: 'https://www.143bet.live', // Replace with your actual home URL
        }),
      });

      const data = await response.json();

      // Log the full response to understand its structure
      console.log('API Response:', data);

      if (response.ok) {
        if (data.response && data.response.response.embed_code) {
          setLoading(false); // Set loading to false after all scripts are loaded
          return data.response.response.embed_code;
        } else {
          throw new Error('Invalid response structure: embed_code not found');
        }
      } else {
        throw new Error(data.message || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('Failed to fetch game details:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchContentFromAPI()
      .then(htmlContent => {
        if (containerRef.current) {
          containerRef.current.innerHTML = htmlContent || 'Failed to load content.';
          const scripts = containerRef.current.querySelectorAll('script');
          const loadPromises = Array.from(scripts).map((originalScript) => {
            if (originalScript.src) {
              
              // Remove the original script element to prevent double execution
              originalScript.parentNode.removeChild(originalScript);
              // Return a promise that resolves when the external script is loaded
              return loadScript(originalScript.src);
            } else {
              // For inline scripts, return null (to be handled after external scripts are loaded)
              return Promise.resolve(null);
            }
          });

          // Wait for all external scripts to load before executing inline scripts
          Promise.all(loadPromises).then(() => {
            // Re-add and execute inline scripts
            Array.from(scripts).forEach((originalScript) => {
              if (!originalScript.src) {
                const script = document.createElement('script');
                script.textContent = originalScript.textContent;
                document.body.appendChild(script);
                // Inline script executed here
              }
            });
            
          }).catch(error => console.error('Failed to load external scripts:', error));
        }
      })
      .catch(error => {
        console.error('Failed to fetch and load content:', error);
        setLoading(false); // Stop loading in case of error
      });
  }, []);

  return (
    <div className='max-width game-lunch'>
      {loading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (


       <>
        {isLoggedIn ? (
        <div className="sports" ref={containerRef}>
          {/* Logged-in user content */}
        </div>
      ) : (
        <iframe 
        title="Game"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        src='https://dev-game-launch.pantaloo.com/players/tpl/game_nb?url=https%3A%2F%2Fstaging-grandslotbet.web.7platform.net%2Flive%3Flang%3Den%26currency%3DEUR&baseUrl=https%3A%2F%2Fstaging-grandslotbet.web.7platform.net%2F&shk=5ba5d7055d9f46ceae5943e618832253f8c6ac7a3bd1c3fe1b32ff1aa35a7292'
        frameBorder="0"
        allowFullScreen
      ></iframe>
      )}
       </>
      )}
    </div>
  );
}

export default Sports;
